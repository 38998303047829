<script>
import GlobalVue from '@helper/Global.vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '@helper/Gen';

export default {
    extends: GlobalVue,
    data() {
        return {
          data:[],
          cat:{}
        }
    },
    watch: {
      $route() {
        this.getCatProduct()
        this.refreshMeta()
      }
    },
    async mounted() {
        this.$set(this.$root, 'page', this)
        this.getCatProduct()
        this.refreshMeta()
        AOS.init({ once: true });
    },
    methods: {
      getCatProduct() {
        Gen.apirest("/product-cat/"+this.$route.params.cat,Gen.clone(this.$route.query), (err, resp) => {
          if (err) console.log(err)
          if (resp.code == 404) return this.$router.push({name:'404'})
          this.data = resp.data
          this.cat = resp.cat
          setTimeout(()=>{            
            SEMICOLON.widget.carousel();
            AOS.init({ once: true });
          }, 300)
        })
      },
      changePage(page=1){
        this.$router.push({query:Object.assign(Gen.clone(this.$route.query),{page:page})})
      },
      refreshMeta(){
        Gen.rest(this.$route.path, {metainfo:true},(err,resp)=>{
          if(err) return console.error(err)
          $("title").text(resp.meta_title)
          $("meta[property='og:image']").attr('content', resp.ss_img)
          $("meta[property='og:title']").attr('content', resp.meta_title)
          $("meta[name='description']").attr('content', resp.ss_meta_desc)
          $("meta[property='og:description']").attr('content', resp.ss_meta_desc)
          $("meta[name='keywords']").attr('content', resp.ss_meta_keyword)
          $("meta[name='author']").attr('content', resp.ss_author)
          $("meta[property='og:site_name']").attr('content', resp.ss_sitename)
          $("meta[property='url']").attr('content', resp.ss_url)
          $("link[rel='canonical']").attr('content', resp.ss_url)
          this.$root.meta = resp
        })
      },
    }
};
</script>

<template>
  <section id="content">
      <div class="content-wrap py-0">
        <section id="page-title" class="page-title-mini">
          <div class="container clearfix">
            <h1>{{cat.apc_name}}</h1>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{name:'Index'}">{{web.menu_home}}</router-link></li>
              <li class="breadcrumb-item"><router-link :to="{name:'Product'}">{{web.menu_product}}</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">{{cat.apc_name}}</li>
            </ol>
          </div>
        </section>
        <section class="section bg-fixer bg_blue_general" :style="cat.apc_id ==1 ? 'background-image:url('+uploader(cat.apc_img_background)+')':''">
          <div class="container">
            <div class="row">
              <div class="col-md-3">
                <div class="form-row align-items-center justify-content-center">
                  <div class="col-7 col-md-12 order-2 order-md-0">
                    <div class="wrap_brand" data-aos="zoom-out">
                      <img :src="uploader(cat.apc_img_logo)" :alt="cat.apc_name" />
                    </div>
                  </div>
                  <div class="col-5 col-md-12" v-if="cat.apc_img_tagline != null">
                    <div class="brand_tagline" data-aos="zoom-out" data-aos-delay="150">
                      <img :src="uploader(cat.apc_img_tagline)" :alt="cat.apc_name" />
                    </div>
                  </div>
                  <div class="col-12 text-center order-3 order-md-2">
                    <a v-if="cat.apc_link != null" :href="cat.apc_link" target="_blank" class="cta cta-primary cta--reveal mt-3 cta--longer"><span>Kunjungi Website</span><i class="icon-line-arrow-right"></i></a>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <div class="row justify-content-center justify-content-md-start product-row" v-if="data.data.length">
                  <div class="col-lg-3 col-md-4 col-6" data-aos="fade-up" v-for="(v,k) in data.data" :data-aos-delay="(k%4)*150">
                    <div class="product_wrapper pad_10">
                      <router-link :to="{name:'ProductDetail',params:{cat:v.apc_slug,slug:v.ap_slug}}"><img :src="uploader(v.ap_img)" :alt="v.ap_name"></router-link>
                      <div class="product_desc">
                        <h3>{{v.ap_name}}</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <nav class="mt_pagination text-center">
                      <Pagination :limit="2" :data="data" @pagination-change-page="changePage" class="pagination justify-content-center">
                        <i slot="prev-nav" class="icon-angle-left"></i>
                        <i slot="next-nav" class="icon-angle-right"></i>
                      </Pagination>
                    </nav>
                  </div>
                </div>
                <div v-else class="row">
                  <div class="col-12 text-center">
                    <div class="notfound notfound--products">
                      <img :src="assets('fo_images', 'search-no-products.png')" alt="" class="notfound__img">
                      <h4 class="notfound__title">{{web.lbl_no_product}}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-bottom" :style="cat.apc_id !=1 ? 'background-image:url('+uploader(cat.apc_img_background)+')':''"></div>
        </section>
      </div>
    </section>
</template>